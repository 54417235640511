var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-alert',{staticClass:"m-0 py-3 px-4",style:({
    position: 'sticky',
    top: 0,
    zIndex: 99,
    backgroundColor: '#fff',
    borderRadius: '0 0 20px 20px',
    border: 'none',
    boxShadow: '0px 0px 25px #aaa',
    color: '#2c3e50',
  }),attrs:{"show":Boolean(_vm.installationPrompt),"fade":""}},[_c('b-row',{style:({ rowGap: '1rem' }),attrs:{"align-h":"end","align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","md":""}},[_c('h4',[_vm._v(_vm._s(_vm.$t("app-install.app-herunterladen")))]),_vm._v(" "+_vm._s(_vm.$t("app-install.install-info"))+" ")]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"link"},on:{"click":_vm.dismiss}},[_c('u',[_vm._v(_vm._s(_vm.$t("app-install.nein-danke")))])]),_c('b-button',{style:({ color: 'white' }),attrs:{"variant":"success"},on:{"click":_vm.install}},[_c('b-icon-download',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t("app-install.installieren"))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }