var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ mobile: _vm.$store.state.isMobile },attrs:{"id":"app"}},[_c('AppInstallWindow'),_c('HeadNav',{attrs:{"onlineStatus":_vm.online,"serverVersion":_vm.serverVersion}}),_c('router-view',{staticClass:"mb-2",style:({ minHeight: 'calc(100vh - 116px)' })}),_c('footer',{staticClass:"p-4 px-5 pt-5 d-flex flex-column align-items-center"},[_c('b-row',{staticClass:"w-75 footer-link",style:({ gap: '20px' }),attrs:{"align-h":"around"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('h5',[_c('b',[_vm._v(_vm._s(_vm.$tc("navigation.internalLinks")))])]),_c('b-button',{attrs:{"variant":"link","to":{ name: 'Home', params: { locale: _vm.$root.$i18n.locale } }}},[_vm._v(" "+_vm._s(_vm.$tc("navigation.StartPage"))+" ")]),_c('br'),_c('b-button',{attrs:{"variant":"link","to":{ name: 'Help', params: { locale: _vm.$root.$i18n.locale } }}},[_vm._v(" "+_vm._s(_vm.$tc("general.help"))+" ")]),_c('br'),_c('b-button',{attrs:{"variant":"link","to":{
            name: 'Impressum',
            params: { locale: _vm.$root.$i18n.locale },
          }}},[_vm._v(" "+_vm._s(_vm.$tc("navigation.impressum"))+" ")]),_c('br'),_c('b-button',{attrs:{"variant":"link","to":{
            name: 'Datenschutz',
            params: { locale: _vm.$root.$i18n.locale },
          }}},[_vm._v(" "+_vm._s(_vm.$tc("navigation.datenschutz"))+" ")]),_c('br'),_c('b-button',{attrs:{"variant":"link"},on:{"click":() => this.$refs.feedbackPrompt.open(true)}},[_vm._v(" "+_vm._s(_vm.$tc("navigation.feedback-geben"))+" ")]),_c('br')],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('h5',[_c('b',[_vm._v(_vm._s(_vm.$tc("navigation.externe-links")))])]),_c('b-button',{attrs:{"variant":"link","href":"https://www.instagram.com/choreoplaner/","target":"_blank"}},[_c('b-icon-instagram',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$tc("navigation.instagram")))],1),_c('br'),_c('b-button',{attrs:{"variant":"link","href":"https://www.facebook.com/choreoplaner/","target":"_blank"}},[_c('b-icon-facebook',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$tc("navigation.facebook")))],1),_c('br'),_c('b-button',{attrs:{"variant":"link","href":"https://github.com/andreasnicklaus/cheer-choreo-tool","target":"_blank"}},[_c('b-icon-github',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$tc("navigation.github")))],1),_c('br')],1)],1),_c('hr',{staticClass:"my-3",style:({ width: '100%', borderColor: '000225' })}),_c('b-row',{staticClass:"mt-1",attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"mr-2"},[_vm._v(" Andreas Nicklaus @"+_vm._s(new Date().getFullYear())+" ")])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('img',{attrs:{"src":"https://uptime.betterstack.com/status-badges/v3/monitor/1l68q.svg","alt":`Status-Anzeige der Server: Server sind ${
            _vm.online ? ' nicht online' : 'online'
          }`,"width":"80","height":"20","loading":"lazy"}})]),_c('b-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"mx-2",style:({
            display: 'inline-block',
            fontFamily: 'monospace',
            fontSize: '0.8em',
          })},[_vm._v(" Version: "+_vm._s(_vm.applicationVersion)+" "),(_vm.serverVersion && _vm.serverVersion != _vm.applicationVersion)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":`Die Version der Webseite (${_vm.applicationVersion}) entspricht nicht der Version der Server (${_vm.serverVersion})!`}},[_c('b-icon-exclamation-triangle-fill')],1):_vm._e()])])],1)],1),_c('ConsentWindow'),_c('FeedbackPrompt',{ref:"feedbackPrompt"}),_c('script',{attrs:{"type":"application/ld+json"}},[_vm._v(" "+_vm._s({ "@context": "https://schema.org/", "@type": "BreadcrumbList", itemListElement: this.$route.path.split("/") .filter((route, index) => index > 0) .map((route, index) => ({ "@type": "ListItem", position: index + 1, name: this.$route.name || route, item: `https://www.choreo-planer.de/${this.$route.path .split("/") .filter((route, index) => index > 0) .slice(0, index + 1) .join("/")}`, })) })+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }