var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":`modal-newChoreo-${_vm.id}`,"title":_vm.$t('nav.neue-choreo'),"centered":"","scrollable":"","size":"xl"},on:{"show":_vm.resetChoreoModal,"ok":_vm.createChoreo},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ ok, cancel }){return [_c('b-button',{attrs:{"variant":"success","disabled":!_vm.newChoreoNameIsValid ||
        !_vm.newChoreoCountIsValid ||
        !_vm.newChoreoAchterIsValid ||
        !_vm.newChoreoTeamIsValid ||
        !_vm.newChoreoSeasonIsValid ||
        _vm.newChoreoParticipantIds.length == 0},on:{"click":ok}},[_vm._v(" "+_vm._s(_vm.$t("erstellen"))+" ")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":cancel}},[_vm._v(_vm._s(_vm.$t("abbrechen")))])]}}])},[_c('b-form',[_c('b-form-group',{attrs:{"label":"Name:","state":_vm.newChoreoNameIsValid,"invalid-feedback":_vm.newChoreoNameStateFeedback,"valid-feedback":_vm.$t('login.gueltig')}},[_c('b-form-input',{attrs:{"state":_vm.newChoreoNameIsValid,"required":"","autofocus":"","placeholder":_vm.$t('modals.create-choreo.example-name', [new Date().getFullYear()])},model:{value:(_vm.newChoreoName),callback:function ($$v) {_vm.newChoreoName=$$v},expression:"newChoreoName"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('modals.create-choreo.laenge')}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"description":_vm.$t('achter'),"state":_vm.newChoreoAchterIsValid,"invalid-feedback":_vm.newChoreoAchterStateFeedback}},[_c('b-form-input',{attrs:{"type":"number","min":"1","state":_vm.newChoreoAchterIsValid},model:{value:(_vm.newChoreoAchter),callback:function ($$v) {_vm.newChoreoAchter=$$v},expression:"newChoreoAchter"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"description":_vm.$t(
                'modals.change-length.counts-zusaetzliche-counts-nach-den-achtern'
              ),"state":_vm.newChoreoCountIsValid,"invalid-feedback":_vm.newChoreoCountStateFeedback}},[_c('b-form-input',{attrs:{"type":"number","min":"0","max":"7","state":_vm.newChoreoCountIsValid},model:{value:(_vm.newChoreoCount),callback:function ($$v) {_vm.newChoreoCount=$$v},expression:"newChoreoCount"}})],1)],1)],1),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("modals.create-choreo.geschaetzte-zeit-timeestimationstring", [ _vm.timeEstimationString, ]))+" ")])],1),_c('b-form-group',{attrs:{"label":"Team:","state":_vm.newChoreoTeamIsValid,"invalid-feedback":_vm.newChoreoTeamStateFeedback}},[_c('b-form-select',{attrs:{"state":_vm.newChoreoTeamIsValid,"required":"","options":_vm.teams.map((t) => ({ value: t.id, text: t.name }))},model:{value:(_vm.newChoreoTeamId),callback:function ($$v) {_vm.newChoreoTeamId=$$v},expression:"newChoreoTeamId"}})],1),_c('b-form-group',{attrs:{"label":"Season:","state":_vm.newChoreoSeasonIsValid,"invalid-feedback":_vm.newChoreoSeasonStateFeedback,"valid-feedback":_vm.$t('login.gueltig')}},[_c('b-form-select',{attrs:{"state":_vm.newChoreoSeasonIsValid,"required":"","disabled":!_vm.newChoreoTeamId,"options":_vm.teams
            .find((t) => t.id == _vm.newChoreoTeamId)
            ?.SeasonTeams?.map((st) => ({
              value: st.Season.id,
              text: st.Season.name,
            }))},model:{value:(_vm.newChoreoSeasonId),callback:function ($$v) {_vm.newChoreoSeasonId=$$v},expression:"newChoreoSeasonId"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('teilnehmer')}},[_c('b-skeleton-wrapper',{attrs:{"loading":!_vm.newChoreoTeamIsValid || !_vm.newChoreoSeasonIsValid},scopedSlots:_vm._u([{key:"loading",fn:function(){return _vm._l((Array(3)),function(_,i){return _c('b-skeleton',{key:i})})},proxy:true}])},[_c('b-button-group',[_c('b-button',{attrs:{"variant":"light","disabled":_vm.newChoreoParticipantIds?.length == _vm.participantOptions?.length},on:{"click":() =>
                (this.newChoreoParticipantIds = _vm.participantOptions.map(
                  (po) => po.value
                ))}},[_c('b-icon-check-all'),_vm._v(" "+_vm._s(_vm.$t("alle-auswaehlen"))+" ")],1),_c('b-button',{attrs:{"variant":"light","disabled":_vm.newChoreoParticipantIds?.length == 0},on:{"click":() => (this.newChoreoParticipantIds = [])}},[_c('b-icon-slash'),_vm._v(" "+_vm._s(_vm.$t("keine-auswaehlen"))+" ")],1),_c('b-button',{attrs:{"variant":"light","disabled":_vm.newChoreoParticipantIds?.length == 0 ||
              _vm.newChoreoParticipantIds?.length == _vm.participantOptions?.length},on:{"click":() =>
                (this.newChoreoParticipantIds = _vm.participantOptions
                  .filter((po) => !_vm.newChoreoParticipantIds.includes(po.value))
                  .map((po) => po.value))}},[_c('b-icon-arrow-repeat'),_vm._v(" "+_vm._s(_vm.$t("auswahl-wechseln"))+" ")],1)],1),_c('b-checkbox-group',{style:({ columnCount: 2 }),attrs:{"id":`participant-checkbox-group-${_vm.id}`,"name":`participant-checkbox-group-${_vm.id}`,"stacked":""},model:{value:(_vm.newChoreoParticipantIds),callback:function ($$v) {_vm.newChoreoParticipantIds=$$v},expression:"newChoreoParticipantIds"}},_vm._l((_vm.participantOptions),function(option){return _c('b-form-checkbox',{key:option.value,attrs:{"value":option.value}},[_c('b-row',{style:({ minWidth: '300px', minHeight: '38px' }),attrs:{"align-h":"between","align-v":"start"}},[_c('b-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(option.text)+" ")]),_c('b-col',{style:({ width: '100px' }),attrs:{"cols":"auto"}},[_c('b-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.newChoreoParticipantIds.includes(option.value)),expression:"newChoreoParticipantIds.includes(option.value)"}],attrs:{"type":"color"},model:{value:(option.color),callback:function ($$v) {_vm.$set(option, "color", $$v)},expression:"option.color"}})],1)],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }