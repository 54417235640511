var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{attrs:{"toggleable":"sm"}},[_c('b-navbar-brand',{attrs:{"to":{ name: 'Home', params: { locale: _vm.$root.$i18n.locale } }}},[_c('img',{attrs:{"src":_vm.$store.getters.isChristmasTime
          ? '/Icon-Christmas.png'
          : _vm.$store.getters.isEasterTime
          ? '/Icon-Easter.png'
          : '/Icon.png',"alt":_vm.$t('choreo-planer-icon'),"width":"50","height":"50"}})]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":{ name: 'Home', params: { locale: _vm.$root.$i18n.locale } },"active-class":_vm.$route.name == 'Home' ? 'router-link-active' : ''}},[_c('b-icon-house-fill',{staticClass:"mr-1"}),_vm._v(" "+_vm._s(_vm.$t("nav.start"))+" ")],1),_c('b-nav-item',{attrs:{"to":{ name: 'Start', params: { locale: _vm.$root.$i18n.locale } },"disabled":!_vm.$store.state.loggedIn}},[_vm._v(" "+_vm._s(_vm.$t("nav.uebersicht"))+" ")]),_c('b-nav-item-dropdown',{attrs:{"text":"Choreos","disabled":!_vm.$store.state.loggedIn},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{class:{ 'router-link-active': _vm.$route.name == 'Choreo' }},[_vm._v(" "+_vm._s(_vm.$tc("choreo", 2))+" ")])]},proxy:true}])},[_vm._l((_vm.teams.filter((t) =>
            t.SeasonTeams.some((st) => st.Choreos?.length > 0)
          )),function(team){return _c('b-dropdown-group',{key:team.id,attrs:{"header":team.name}},[_vm._l((team.SeasonTeams.filter(
              (st) => st.Choreos?.length > 0
            )),function(seasonTeam){return _c('b-dropdown-text',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${seasonTeam.id}`),expression:"`collapse-${seasonTeam.id}`"}],key:seasonTeam.id,staticClass:"dropdown-submenu"},[_c('span',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(" "+_vm._s(seasonTeam.Season.name)+" "),_c('b-icon-caret-down-fill',{staticClass:"ml-auto",attrs:{"variant":"secondary"}})],1),_c('b-collapse',{attrs:{"id":`collapse-${seasonTeam.id}`}},_vm._l((seasonTeam.Choreos),function(choreo){return _c('b-dropdown-item',{key:choreo.id,attrs:{"to":{
                  name: 'Choreo',
                  params: {
                    choreoId: choreo.id,
                    locale: _vm.$root.$i18n.locale,
                  },
                }}},[_vm._v(" "+_vm._s(choreo.name)+" ")])}),1)],1)}),_c('b-dropdown-divider')],2)}),(_vm.$store.state.clubId)?_c('b-dropdown-item',{attrs:{"variant":"success"},on:{"click":() => _vm.$refs.createChoreoModal.open()}},[_c('b-icon-plus'),_vm._v(" "+_vm._s(_vm.$t("nav.neue-choreo"))+" ")],1):_vm._e()],2),_c('b-nav-item-dropdown',{attrs:{"disabled":!_vm.$store.state.loggedIn},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{class:{ 'router-link-active': _vm.$route.name == 'Team' }},[_vm._v(" "+_vm._s(_vm.$tc("team", 2))+" ")])]},proxy:true}])},[_vm._l((_vm.teams),function(team){return _c('b-dropdown-item',{key:team.id,attrs:{"to":{
            name: 'Team',
            params: { teamId: team.id, locale: _vm.$root.$i18n.locale },
          }}},[_vm._v(" "+_vm._s(team.name)+" ")])}),(_vm.teams && _vm.teams.length > 0)?_c('b-dropdown-divider'):_vm._e(),(_vm.$store.state.clubId)?_c('b-dropdown-item',{attrs:{"variant":"success"},on:{"click":() => _vm.$refs.createTeamModal.open()}},[_c('b-icon-plus'),_vm._v(" "+_vm._s(_vm.$t("nav.neues-team"))+" ")],1):_vm._e()],2)],1),_c('b-navbar-nav',{staticClass:"ml-auto align-items-sm-center"},[_c('b-nav-item-dropdown',{attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('flag',{attrs:{"squared":false,"iso":_vm.flags.find((f) => f.lang == _vm.$root.$i18n.locale)?.flag}}),(_vm.$store.state.isMobile)?_c('span',[_vm._v(" "+_vm._s(_vm.flags.find((f) => f.lang == _vm.$root.$i18n.locale)?.localName)+" ")]):_vm._e()]},proxy:true}])},_vm._l((_vm.flags),function({ lang, flag, localName },i){return _c('b-dropdown-item',{key:`lang${i}`,attrs:{"value":lang},on:{"click":() => _vm.LanguageService.setLanguage(lang)}},[_c('flag',{staticClass:"mr-1",attrs:{"squared":false,"iso":flag}}),_c('span',[_vm._v(_vm._s(localName))])],1)}),1),(_vm.shareable)?_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.$t('nav.teilen')},on:{"click":_vm.share}},[_c('b-icon-share'),_c('span',{staticClass:"d-sm-none ml-2"},[_vm._v(_vm._s(_vm.$t("nav.teilen")))])],1):_vm._e(),(_vm.onlineStatus != null)?_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-sm-block d-none",attrs:{"title":_vm.onlineStatus
            ? _vm.$t('nav.server-sind-online') +
              (_vm.serverVersion && ` (${_vm.serverVersion})`)
            : _vm.$t('nav.server-sind-offline')}},[(_vm.onlineStatus === true)?_c('b-icon-check-circle',{attrs:{"variant":"success"}}):_vm._e(),(_vm.onlineStatus === false)?_c('b-icon-x-circle',{attrs:{"variant":"danger"}}):_vm._e()],1):_vm._e(),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{ name: 'Help', params: { locale: _vm.$root.$i18n.locale } },"title":_vm.$t('general.help')}},[_c('b-icon-question-circle'),_c('span',{staticClass:"d-sm-none ml-2"},[_vm._v(_vm._s(_vm.$t("general.help")))])],1),_c('b-nav-item',{attrs:{"to":_vm.$store.state.loggedIn
            ? null
            : { name: 'Login', params: { locale: _vm.$root.$i18n.locale } }}},[(!_vm.$store.state.loggedIn)?_c('b-button',{style:({ color: 'white' }),attrs:{"variant":"primary","block":_vm.$store.state.isMobile}},[_vm._v(" "+_vm._s(_vm.$t("anmelden"))+" ")]):_c('b-dropdown',{attrs:{"variant":_vm.$store.state.isMobile ? 'outline-secondary' : 'light',"right":"","block":_vm.$store.state.isMobile},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-person-circle'),(_vm.$store.state.isMobile)?_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.user?.username))]):_vm._e()]},proxy:true}])},[_c('b-dropdown-group',{attrs:{"header":_vm.$t('konto')}},[_c('b-dropdown-item',{attrs:{"to":{
                name: 'Account',
                params: { locale: _vm.$root.$i18n.locale },
              }}},[_c('b-icon-person-circle',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.user?.username)+" ")],1)],1),_c('b-dropdown-divider'),_c('b-dropdown-group',{attrs:{"header":_vm.$t('nav.vereine')}},_vm._l((_vm.clubs),function(club){return _c('b-dropdown-item',{key:club.id,attrs:{"variant":club.id == _vm.$store.state.clubId ? 'primary' : null},on:{"click":function($event){return _vm.selectCurrentClub(club.id)}}},[_vm._v(" "+_vm._s(club.name)+" ")])}),1),_c('b-dropdown-item',{attrs:{"variant":"success"},on:{"click":() => _vm.$refs.createClubModal.open()}},[_c('b-icon-plus'),_vm._v(" "+_vm._s(_vm.$t("nav.neuer-verein"))+" ")],1),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"variant":"danger"},on:{"click":_vm.logout}},[_c('b-icon-door-open',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t("nav.ausloggen"))+" ")],1)],1)],1)],1)],1),_c('CreateClubModal',{ref:"createClubModal",on:{"clubCreated":_vm.reloadPage}}),_c('CreateChoreoModal',{ref:"createChoreoModal",attrs:{"teams":_vm.teams},on:{"addChoreo":_vm.reloadPage}}),(_vm.$store.state.loggedIn)?_c('CreateTeamModal',{ref:"createTeamModal",on:{"teamCreated":_vm.onTeamCreated}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }